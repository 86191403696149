export default class {
    constructor({
        id,
        activeClass,
    }) {
        const fieldWrapClass = 'input';
        const inputClass = 'input__input';

        // Elements and class variables
        const el = document.getElementById(id);

        // Event handler functions
        function showLabel(wrapper) {
            if (wrapper) wrapper.classList.add(activeClass);
        }
        function hideLabel(wrapper) {
            if (wrapper) wrapper.classList.remove(activeClass);
        }
        function handleCommonLabels() {
            // nodes are dynamic DOM additions and can only be captured after the form loads
            const inputs = [...el.querySelectorAll('input')]
                .filter(i => i.tagName !== 'SELECT' && i.type !== 'checkbox' && i.type !== 'radio' && i.type !== 'hidden');

            Array.from(inputs).forEach(o => {
                o.addEventListener('focus', () => {
                    showLabel(o.closest(`.${fieldWrapClass}`));
                });
                o.addEventListener('blur', () => {
                    if (o.value === '') {
                        hideLabel(o.closest(`.${fieldWrapClass}`));
                    }
                });
            });
        }
        function handleCheckboxes() {
            const checkboxGroups = el.querySelectorAll('.input--checkbox');
            Array.from(checkboxGroups).forEach(o => {
                if (o.querySelectorAll('input[type="checkbox"]').length === 1) {
                    o.closest(`.${fieldWrapClass}`).classList.add('is-checkbox');
                } else {
                    o.closest(`.${fieldWrapClass}`).classList.add('is-checkboxes');
                }
            });
        }
        function handleRadios() {
            const radioGroups = el.querySelectorAll('.input--radio');
            Array.from(radioGroups).forEach(o => {
                const className = o.querySelectorAll('input[type="radio"]').length === 1
                    ? 'is-radio'
                    : 'is-radios';

                o.closest(`.${fieldWrapClass}`).classList.add(className);
            });
        }
        function handlePhoneNumbers() {
            const phoneNumberInputs = el.querySelectorAll('input[type="tel"]');

            // Add phone number validation for E.164 format
            phoneNumberInputs.forEach(o => {
                o.addEventListener('input', () => {
                    let value = o.value;

                    // Remove any non-digit characters
                    value = value.replace(/\D/g, '');

                    // Remove leading zeros
                    if (value.startsWith('0')) {
                        value = value.replace(/^0+/, '');
                    }

                    // Update the input value
                    o.value = value;

                    // Limit the maximum number of characters to 12
                    if (value.length > 12) {
                        o.value = value.slice(0, 12);
                    }
                });
            });
        }
        function handlePrepopulatedInputs() {
            const prepopulatedInputs = [...el.querySelectorAll(`.${inputClass}`)]
                .filter(i => i.value !== '');

            Array.from(prepopulatedInputs).forEach(o => {
                showLabel(o.closest(`.${fieldWrapClass}`));
            });
        }

        // Initialize
        handleCommonLabels();
        handleCheckboxes();
        handleRadios();
        handlePhoneNumbers();
        handlePrepopulatedInputs();
    }
}
